import axios from 'axios';
import config from '../config';

const apiInstance = axios.create({
  baseURL: config.ENDPOINT_API_URL_LOCAL,
  headers: {
    'Content-Type': 'application/json',
  },
});


const makeRequest = async (method, endpoint, data = null, customHeaders = null) => {
  try {
    const headers = customHeaders
      ? { ...apiInstance.defaults.headers, ...customHeaders }
      : apiInstance.defaults.headers;

    const config = {
      method,
      url: endpoint,
      data: data,  
      headers: headers,
      params: method === 'get' ? data : undefined,
    };

    const response = await apiInstance(config);
    return response.data;
  } catch (error) {
    console.error(`Error with ${method} request to ${endpoint}:`, error);
    throw error;
  }
};


export const request = (method, endpoint, data = null, customHeaders = null) => {
  return makeRequest(method, endpoint, data, customHeaders);
};
