import { request } from './ApiServices';
import CryptoJS from 'crypto-js';
import axios from 'axios';



export async function fetchUserWalletBalanceData(user_email) {
  const endpoint = "/getUserWalletByEmail";
  const customHeaders = {
    'Content-Type': 'application/json',
  };

  const response = await request('get', endpoint, { userEmail: user_email }, customHeaders);
  return response;
}

export async function AddFundInWallet(payload) {

  const endpoint = '/addwalletFundByUser'
  const customHeaders = {
    'Content-Type': 'application/json',
    'Accept':'application/json'
  
  };

  const response = await request('post', endpoint, payload, customHeaders);
  return response;

}


export async function getsubscriptionId(payload) {

  const endpoint = '/getUserProductSubscription'
  const customHeaders = {
    'Content-Type': 'application/json',
  };

  const response = await request('post', endpoint, payload, customHeaders);
  return response;

}


export async function getAllModelByProductID(subscriptionID) {
  const endpoint = "/getAllModelsBySubscriptionID";
  const customHeaders = {
    'Content-Type': 'application/json',
  };

  const response = await request('get', endpoint, { subscriptionID: subscriptionID }, customHeaders);
  return response;
}

export async function ActiveSubUser(payload) {

  const endpoint = '/ActiveSubUser'
  const customHeaders = {
    'Content-Type': 'application/json',
  };

  const response = await request('post', endpoint, payload, customHeaders);
  return response;
}

export async function AzureFreeSub(payload) {

  const endpoint = '/azureFreeSub'
  const customHeaders = {
    'Content-Type': 'application/json',
  };

  const response = await request('post', endpoint, payload, customHeaders);
  return response;
}

export async function fetchUserSubscriptionData(payload) {

  const endpoint = '/getdata'
  const customHeaders = {
    'Content-Type': 'application/json',
  };

  const response = await request('post', endpoint, payload, customHeaders);
  return response;
}

export async function regenerateSecretKey(payload) {

  const endpoint = '/9335347895'
  const customHeaders = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  };

  const response = await request('post', endpoint, payload, customHeaders);
  return response;
}

export async function fetchProductDetails(payload) {

  const endpoint = '/getProductDetails'
  const customHeaders = {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': '99c4cfca3a2c48409c095fb1b4689f4b'
  };

  const response = await request('post', endpoint, payload, customHeaders);
  return response;
}

export async function storeRecentViewDetails(payload) {

  const endpoint = '/storeRecentViewByUser'
  const customHeaders = {
      'Content-Type': 'application/json',
  };

  const response = await request('post', endpoint, payload, customHeaders);
  return response;
}

export async function getRecentViewDataProductDetails(payload) {

  const endpoint = '/getRecentViewDataProducts'
  const customHeaders = {
      'Content-Type': 'application/json',
  };

  const response = await request('post', endpoint,payload, customHeaders);
  return response;
}
export async function getSuggestedModels(payload={}) {

  const endpoint = '/getSuggestedproductsModals'
  const customHeaders = {
      'Content-Type': 'application/json',
  };

  const response = await request('get', endpoint,payload, customHeaders);
  return response;
}
export async function productcodeDetailsByName(payload) {

  const endpoint = '/getproductCodeDetailsModel'
  const customHeaders = {
      'Content-Type': 'application/json',
  };

  const response = await request('get', endpoint,{product_name:payload}, customHeaders);
  return response;
}



export async function AddFundInWalletFirstTIme(payload) {

  const apiUrl = 'https://checkout-dev.pbodev.info/api'; 
  console.log('Sending request to API:', apiUrl);

  try {
    // Encrypt the payload
    const encryptedText = newEncyptionDecryption('encrypt', JSON.stringify(payload));

    // Send the request using axios
    const response = await axios.post(apiUrl, encryptedText, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/plain', 
      }
    });

    if (response.status !== 200) {
      throw new Error(`Error: ${response.statusText}`);
    }

    // Decrypt the response data
    const decryptedText = newEncyptionDecryption('decrypt', response.data);

    // Parse the decrypted text into JSON
    const responseData = JSON.parse(decryptedText);

    return responseData;

  } catch (error) {
    console.error('Error:', error);
  }
}

export async function getCreditBalanceFromEmail(email) {
  const payload = JSON.stringify({
    email: email,
    method: "getCreditBalanceFromEmail"
  });

  console.log('payload for getCreditBalanceFromEmail=', payload);

  // Encrypt the string
  const encryptedText = newEncyptionDecryption('encrypt', payload);

  try {
    const response = await fetch('https://checkout-dev.pbodev.info/api', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain'
      },
      body: encryptedText,
    });

    if (!response.ok) {
      throw new Error(`Failed to call external API: ${response.statusText}`);
    }

    const responseText = await response.text();
    const decryptedText = newEncyptionDecryption('decrypt', responseText);
    console.log('decryptedText for getCreditBalanceFromEmail=', decryptedText);

    // Assume the decrypted text is a valid JSON string
    return JSON.parse(decryptedText);

  } catch (error) {
    console.error(`Error in checkout.appypie.com/api method getCreditBalanceFromEmail for email ${email}:`, error.message);
    throw error;
  }
}








































const newEncyptionDecryption = (action, string) => {
  const encryptionMethod = 'AES';
  const secret = '&idh74zD37SsjnjnsADD##@!!Wsnjd$3';
  const iv = 'dmKn#3&6dh@!hjs!';

  if (action === 'encrypt') {
    const encrypted = CryptoJS.AES.encrypt(string, CryptoJS.enc.Utf8.parse(secret), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  } else if (action === 'decrypt') {
    const decrypted = CryptoJS.AES.decrypt(string, CryptoJS.enc.Utf8.parse(secret), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } else {
    throw new Error('Invalid action. Use "encrypt" or "decrypt".');
  }
};






