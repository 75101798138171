// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './static/css/Style.css'; // Import the CSS file
import Header from './components/Header';
// import Footer from './components/Footer';
import HomePage from './components/home/HomePage';
import Login from './components/signin/Login';
import Delegate from './components/delegate/Delegate';
import Dashboard from './components/dashboard/Dashboard';
import AfterPaymentSuccess from './components/dashboard/AfterPaymentSuccess';
import PaymetSuccessPage from './components/payments/PaymetSuccessPage';
//import Products from './components/product/Products';
//import APIs from './components/apis/APIs'; // You need to create APIs component similarly
import Snappy from './components/snappy/Snappy';
/*
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/delegate" element={<Delegate />} />
      </Routes>
      <Footer />
    </Router>
  );
} */

function App() {
  return (
    <Router>
      <Header />
      <MainRoutes />
      {/* <Footer /> */}
    </Router>
  );
}

function MainRoutes() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const group = params.get('group');

  // Determine the component based on the 'group' query parameter
  const HomeComponent = group === 'snappy' ? Snappy : HomePage;

  return (
    <Routes>
      <Route path="/" element={<HomeComponent />} />
      <Route path="/login" element={<Login />} />
      <Route path="/delegate" element={<Delegate />} />
      <Route path="/dashboard" element={<Dashboard />}/>
      <Route path="/payment-success" element={<PaymetSuccessPage />}/>
      <Route path="/api_keys" element={<AfterPaymentSuccess />}/>
      {/* <Route path="/products" element={<Products />} />
      <Route path="/apis" element={<APIs />} /> */}
      {/* Add more routes here if needed */}
    </Routes>
  );
}

export default App;

