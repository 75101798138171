const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  ENDPOINT_API_URL: process.env.REACT_APP_ENDPOINT_API_URL,
  ENDPOINT_API_URL_LOCAL: process.env.REACT_APP_ENDPOINT_API_URL_LOCAL,
  SNAPPY_URL: process.env.REACT_APP_SNAPPY_URL,
  MARKETING_URL: process.env.REACT_APP_MARKETING_URL,
};


console.log('config=', config);
export default config;
