import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import config from '../config';
import { logInDevelopment } from '../utils'; 
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchUserWalletBalanceData,ActiveSubUser,AzureFreeSub } from "../services/DashboardServices";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [userFromApi, setUserFromApi] = useState(null);
  const [checkUserSubscription,setCheckUserSubscription] = useState(null);
  const [walletdata, setwalletdata] = useState({
    amount: 0,
    paymentMode: '',
    user_status :false
  });
  const productName = 'appypieapi-ai';

  useEffect(() => {
    const loginStatus = Cookies.get('79fefaf2564aabb19d44fe324844c86b');
    
    if (loginStatus === undefined) {
      //window.location.href = 'https://appypie.com/endpoint';
      window.location.href = `${config.MARKETING_URL}`;
    } else if (loginStatus === '1') {
     
      const appyId = Cookies.get('APPYID');
      if (appyId) {
        const userdata = fetchUserDetails(appyId);
        if(userdata){
          fetchUserWalletData();
        }
      }
    }
  }, []);
  

  const fetchUserDetails = async (appyId) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/common/getuserdetailnew`, {
        file_name: appyId
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response || !response.data) {
        console.error('Invalid API response:', response);
        return;
      }
  
      console.log('API Response Data:', response.data);
  
      const { userdata } = response.data;
      if (!userdata) {
        setIsAuthenticated(false);
        console.error('No userdata found in the response:', response.data);
        window.location.href = `${config.MARKETING_URL}`;
        return;
      }
  
      setUserDetails(userdata);
      setIsAuthenticated(true);
  

      const { email, first_name: firstName, last_name: lastName, c_id: userId } = userdata;
  
      if (email) {
        const lName = lastName || firstName;
  
  
        fetchUserByEmail({ email, firstName, lName, userId });
       
          fetchUserWalletData({ email: email }); 
       
      } else {
        console.error('Email not found in user data');
      }
  
    } catch (error) {
  
      handleError(error);
    }
  };
  
  // Simplified error handling function
  const handleError = (error) => {
    if (error.response) {
      console.error('Error response data:', error.response.data);
  
      if (error.response.data.message === 'User not found') {
        window.location.href = `${config.MARKETING_URL}`;
      } else {
        console.error('Error response status:', error.response.status);
        console.error('Full error response:', error.response);
      }
    } else if (error.request) {
      console.error('Error request (No response received):', error.request);
    } else {
      console.error('Error message:', error.message);
    }
  
    // Additional logging for debugging purposes
    if (error.config) {
      console.error('Error config:', error.config);
    }
  };
  
  const fetchUserByEmail = async ({ email, firstName, lName: lastName, userId }) => {
    try {
      const response = await axios.post(`${config.ENDPOINT_API_URL}/getUser`, {
        email: email,
        firstName: firstName,
        lastName: lastName,
        userId: userId
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setUserFromApi(response.data);
    } catch (error) {
      console.error('Error fetching user by email:', error);
    }
  };

  useEffect(() => {
    const fetchUserProductSubscription = async (userId, productName) => {
        try {
            logInDevelopment('Checking user product subscription for:', productName);
            const payload = {
                userId: userId,
                productName: productName,
                status: 'active',
                appid: ''
              };
            
            const response = await ActiveSubUser(payload);
            console.log("ActiveSubUser",response,);
            logInDevelopment('User product subscription response:', response.data,);
            if (response && response.length > 0) {
                const subscription = response.find(item => item.productName === productName);
                setCheckUserSubscription(subscription);
                return checkUserSubscription;
            } else {
                logInDevelopment('No active subscription found. Creating a new free subscription.');
                const subId = 'api_' + Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000 + '_' + Math.floor(Date.now() / 1000);

                const payload={
                    subscriptionId: subId,
                    displayName: 'planId-free',
                    ownerId: userId,
                    scope: productName
                }
                const createResponse = await AzureFreeSub(payload);
                logInDevelopment('Free subscription creation response:', createResponse.data);
                if (createResponse.data && createResponse.data.token) {
                    return fetchUserProductSubscription(userId, productName);
                } else {
                  return toast.error("Failed to create subscription");
                }
            }
        } catch (error) {
            console.error('Error checking user product subscription:', error);
             toast.error("Failed to create subscription");
            return false;
        }
    };

    if (isAuthenticated && userFromApi) {
        (async () => {
            const planId = await fetchUserProductSubscription(userFromApi.document.name, productName);
        })();
    }
}, [isAuthenticated, userFromApi]);


  const fetchUserWalletData = async ({ email } = {}) => { // Default to empty object

    try {
      // if (!email) {
      //   console.error("Email is required to fetch wallet data");
      //   return;
      // }
      const data = await fetchUserWalletBalanceData(email);
  
      if (data.status === 200 && data.message === 'success') {
        setwalletdata({
          amount: data.response.amount,
          paymentMode: data.response.payment_mode,
          user_status:true
        });
      }

    } catch (error) {
      console.error('Error fetching payment mode:', error);
    }
  };
  
  return (
    <AuthContext.Provider value={{ isAuthenticated, userDetails, userFromApi ,walletdata,checkUserSubscription}}>
      {children}
    </AuthContext.Provider>
  );
};


