import React, { useState ,useContext,useEffect} from "react";
import { useNavigate,useLocation } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import { toast,ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';


function SidePanel({ step }) {

    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const { walletdata } = useContext(AuthContext);
    const [productmodel, SetproductModel] = useState('');
    const location = useLocation();

    useEffect(() => {
        const product = Cookies.get('productParam');
        if (product) {
          SetproductModel(product);
        }
      }, [location,productmodel]);
    

      const handleApiKeyNavigation = () => {
        if (walletdata?.amount > 0) {
            const path = productmodel && productmodel !== 'null' 
                ? `/api_keys?product=${productmodel}` 
                : '/api_keys';
            navigate(path);
        } else {
            toast.warning("Please add some funds to your wallet.");
        }
    };
    const dashboardnavigation=()=>{
        const path = productmodel && productmodel !== 'null' 
        ? `/dashboard?product=${productmodel}` 
        : '/dashboard';
    navigate(path);
    }
     

    return (
        <div className="col-md-12 col-lg-4">
            <div className="aside-card bg-white mb-4 mb-lg-0">
                <div className="p-4 listed d-flex gap-2 gap-md-3 flex-column align-items-start justify-content-start">
                    <img src="./endpoint-api.svg" alt="Endpoint" width="80" height="80" />
                    <h1>Welcome to Endpoint!</h1>
                    <h2>Follow these steps to complete you journey. Use APIs without any interruptions. Contact our support if get stuck anywhere! </h2>
                </div>
                <div className="d-flex gap-3 align-items-start p-4 border-top completed">
                    <div className="step-icon">
                    </div>
                    <div className="step-content">
                        <h3>Create an account</h3>
                        <p>You can view API consumption on your dashboard and details of every request in reports sections</p>
                    </div>
                </div>
                <div className={`d-flex gap-3 align-items-start p-4 ${step === "3" ? 'border-top completed' : step === "2" ? 'active' : ''}`}
                    onClick={() => dashboardnavigation()}>
                    <div className="step-icon"> </div>
                    <div className="step-icon warning" style={{ display: isVisible ? "block" : "none" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                            <g clipPath="url(#clip0_350_1891)">
                                <path d="M6 2.2002V7.27813M6 10.8702H6.00846" stroke="white" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                        </svg>

                    </div>
                    <div className="step-content">
                        <h3>Add Funds</h3>
                        <p>Add funds and activate your wallet. You will get full access to all APIs once your wallet is activated with funds.</p>
                    </div>
                </div>
                <div className={`d-flex gap-3 align-items-start p-4 ${step === "3" ? 'active' : step === "2" ? 'pending' : ''}`} onClick={() => handleApiKeyNavigation()}>
                    <div className="step-icon"> </div>
                    <div className="step-content">
                        <h3>Get API Key to AI models</h3>
                        <p>Explore our library of AI models for text, image and speech generation and run on your platform with any programming language.</p>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default SidePanel;


