import React, { useEffect } from "react";
import "./PaymentSuccessPageStyle.css"
import { useNavigate } from 'react-router-dom'; 

function PaymetSuccessPage() {

    const navigate = useNavigate(); // Hook to navigate programmatically


    // useEffect(()=>{
    //     afterPaymentSuccess();
    // })

    const goToDashboard = () => {
      navigate('/dashboard/api_keys'); 
    };

    return (
        <div className="payment-success">
            <div className="success-content">
                <h1 className="star-icon">⭐Payment Success⭐</h1>
            </div>

            <button className="gotobutton" onClick={goToDashboard}>Go to Dashboard</button>

        </div>
    );

}

export default PaymetSuccessPage