import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import { AuthContext } from '../../context/AuthContext';
import { getAllModelByProductID } from "../../services/DashboardServices";
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const Dropdown = () => {
    const [isActive, setIsActive] = useState(false);
    const [selectedItem, setSelectedItem] = useState("Select Model");
    const [allModels, setAllModels] = useState([]); 
    const [searchQuery, setSearchQuery] = useState("");
    const { checkUserSubscription } = useContext(AuthContext);
    const dropdownRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsActive((prev) => !prev);
    };

    const handleItemClick = (model) => {
        setIsActive(false);
        setSelectedItem(model.name);
        setSearchQuery(model.name);
        
        Cookies.set('productParam', model.name, { expires: 7, path: '' });  
        const path = `/api_keys?product=${model.name}` 
        navigate(path);
    };

    // const handleClickOutside = useCallback((e) => {
        
    //     if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        
    //         setIsActive(false);
    //     }
    // }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const productParam = queryParams.get('product');
        if(productParam && productParam !== "null")
            setSelectedItem(productParam);
    }, [location.search]);

    useEffect(() => {
		const handleClickOutside = (event) => {
		  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsActive(false); 
		  }
		};
	
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

    
    const getAllModelsBySubscriptionID = useCallback(async () => {
        if (checkUserSubscription) {
            const response = await getAllModelByProductID(checkUserSubscription.productName);
            if (response.status === 200) {
                setAllModels(response.response.apis);
            }
        }
    }, [checkUserSubscription]);

    // useEffect(() => {
    //     document.addEventListener("click", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("click", handleClickOutside); 
    //     };
    // }, [handleClickOutside]);

    useEffect(() => {
        getAllModelsBySubscriptionID();
    }, [checkUserSubscription, getAllModelsBySubscriptionID]);

    
    const handleSearchChange = (event) => {
    
        setSearchQuery(event.target.value);
    };

    const filteredModels = allModels.filter((model) =>
        model.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="d-flex gap-2 flex-column" ref={dropdownRef}>
            <h4 className="mb-0">Select a Model:</h4>
            <div className={`dropdown ${isActive ? "active" : ""}`}  >
                {isActive ? (
                    <>
                    <div className="dropdown-selected w-100">
                    <input
                        type="text"
                        className="form-control border-0 bg-transparent p-0 "
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search models..."
                    />
                    <svg onClick={toggleDropdown} 
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                        </svg>
                        </div>
                    </>
                ) : (
                    <div className="dropdown-selected" onClick={toggleDropdown} > 
                        {selectedItem}
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                        </svg>
                    </div>
                )}

                {isActive && (
                    <ul className="dropdown-list scrollheight">
                        {filteredModels.length > 0 ? (
                            filteredModels.map((model) => (
                                <li
                                    key={model.id}
                                    className={`dropdown-item ${model.name === selectedItem ? 'active' : ''}`}
                                    onClick={() => handleItemClick(model)}
                                >
                                    {model.name}
                                </li>
                            ))
                        ) : (
                            <li className="dropdown-item" disabled>No models available</li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Dropdown;
