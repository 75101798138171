import React, { useState, useContext, useEffect } from "react";
import CryptoJS from 'crypto-js';
import { AuthContext } from '../../context/AuthContext';
import { logInDevelopment } from '../../utils';
import { regenerateSecretKey,productcodeDetailsByName } from "../../services/DashboardServices";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';

const APIKeysForm = () => {

    const [showPrimaryKey, setShowPrimaryKey] = useState(false);
    const [showSecondaryKey, setShowSecondaryKey] = useState(false);
    const [loadingPrimaryKey, setLoadingPrimaryKey] = useState(false);
    const [loadingSecondaryKey, setLoadingSecondaryKey] = useState(false);
    const ENCRYPTION_KEY = 'HareRamaHareKrishna';
    const [error, setError] = useState(null);
    const { checkUserSubscription, userDetails, walletdata } = useContext(AuthContext);
    const [secrets, setSecrets] = useState({
        primaryKey: '',
        secondaryKey: ''

    });
    const [code,SetCode]=useState({
        http:"Not Available",
        curl:"Not Available",
        python:"Not Available",
        js:"Not Available",
    });
    const [productmodel, SetproductModel] = useState('');
    const location = useLocation();

    useEffect(() => {
        const product = Cookies.get('productParam');
        if (product) {
          SetproductModel(product);
          handleProductCodeDetails(product);
        }
      }, [location,productmodel]);

    const encryptJSONData = (data) => {
        return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
    };

    const hendleGetSecrets = async () => {
        if (checkUserSubscription) {
            setSecrets({
                primaryKey: checkUserSubscription.keys.primaryKey,
                secondaryKey: checkUserSubscription.keys.secondaryKey,
            });
        }
    };

    const handleGenerateSecrets = async (action) => {
        logInDevelopment(`Regenerating ${action}`);
        const secData = { snd: checkUserSubscription.subscriptionId, action };
        const encData = encryptJSONData(secData);

        if (action === 'regeneratePrimaryKey') setLoadingPrimaryKey(true);
        if (action === 'regenerateSecondaryKey') setLoadingSecondaryKey(true);

        try {
            const payload = {
                encpayload: encData,
            };
            const response = await regenerateSecretKey(payload);

            setSecrets({
                primaryKey: response.primaryKey,
                secondaryKey: response.secondaryKey
            });
        } catch (error) {
            console.error('Error fetching secrets:', error);
            setError('Failed to fetch secrets. Please try again later.');
        } finally {
            if (action === 'regeneratePrimaryKey') setLoadingPrimaryKey(false);
            if (action === 'regenerateSecondaryKey') setLoadingSecondaryKey(false);
        }
    };

    async function handleProductCodeDetails(product){
        const response = await productcodeDetailsByName(product);
        
         if(response.status === 200 && response.response.productDetails[0]?.code){
            SetCode({       
                http:response.response.productDetails[0]?.code?.http?.code,
                curl:response.response.productDetails[0]?.code?.curl?.code,
                python:response.response.productDetails[0]?.code?.python?.code,
                js:response.response.productDetails[0]?.code?.javascript?.code,
            });
        
         }
    }

    useEffect(() => {
        try {
            hendleGetSecrets();
        } catch (e) {
            setError(e.message);
        }
    }, [checkUserSubscription]);

    const maskKey = (key) => {
        if (key.length <= 5) {
            return key;
        }
        return 'x'.repeat(key.length - 5) + key.slice(-5);
    };

    return (
        <div className="d-flex gap-3 flex-column">
            <div className="d-flex justify-content-between gap-3 flex-wrap">
                <h4 className="mb-0">API Token: </h4>
                <a href="#" className="fw-semibold small">View API documentation</a>
            </div>
            <div className="d-flex gap-2 flex-column">
                <h4 className="mb-0 fw-normal">Primary Key</h4>
                <div className="primarykey">
                    <input
                        type="text"
                        id="primary-key"
                        className="form-control bg-transparent border-0 px-0"
                        value={loadingPrimaryKey ? 'Loading...' : showPrimaryKey ? secrets.primaryKey : maskKey(secrets.primaryKey)}
                        readOnly
                    />
                    <div className="d-flex align-items-center gap-2">
                        <button
                            className="btn toggle-visibility"
                            type="button"
                            onClick={(e) => { e.preventDefault(); setShowPrimaryKey(!showPrimaryKey); }}
                        >
                            {showPrimaryKey ? (
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                 <path
                                     d="M8.94394 4.23033C10.8851 3.99899 12.8486 4.40934 14.5347 5.3987C16.2207 6.38806 17.5366 7.90205 18.2814 9.70949C18.3509 9.89659 18.3509 10.1024 18.2814 10.2895C17.9752 11.032 17.5704 11.7299 17.0781 12.3645M11.7364 11.7987C11.2649 12.2541 10.6334 12.506 9.97793 12.5003C9.32244 12.4946 8.69541 12.2317 8.23189 11.7682C7.76837 11.3047 7.50545 10.6777 7.49975 10.0222C7.49406 9.36667 7.74604 8.73516 8.20144 8.26366M14.5656 14.5828C13.4602 15.2376 12.2268 15.647 10.9492 15.7832C9.67165 15.9193 8.37973 15.779 7.16113 15.3719C5.94253 14.9647 4.82576 14.3002 3.88661 13.4234C2.94746 12.5467 2.2079 11.4781 1.7181 10.2903C1.64865 10.1032 1.64865 9.89742 1.7181 9.71033C2.45696 7.91854 3.757 6.4147 5.4231 5.42449M1.66644 1.66699L18.3331 18.3337"
                                     stroke="#2967F6" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"
                                 />
                             </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path
                                        d="M1.7181 10.2898C1.64865 10.1027 1.64865 9.89691 1.7181 9.70981C2.39452 8.06969 3.5427 6.66735 5.01708 5.68056C6.49146 4.69378 8.22564 4.16699 9.99977 4.16699C11.7739 4.16699 13.5081 4.69378 14.9825 5.68056C16.4568 6.66735 17.605 8.06969 18.2814 9.70981C18.3509 9.89691 18.3509 10.1027 18.2814 10.2898C17.605 11.9299 16.4568 13.3323 14.9825 14.3191C13.5081 15.3058 11.7739 15.8326 9.99977 15.8326C8.22564 15.8326 6.49146 15.3058 5.01708 14.3191C3.5427 13.3323 2.39452 11.9299 1.7181 10.2898Z"
                                        stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"
                                    />
                                    <path
                                        d="M9.99977 12.4998C11.3805 12.4998 12.4998 11.3805 12.4998 9.99981C12.4998 8.6191 11.3805 7.49981 9.99977 7.49981C8.61906 7.49981 7.49977 8.6191 7.49977 9.99981C7.49977 11.3805 8.61906 12.4998 9.99977 12.4998Z"
                                        stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"
                                    />
                                </svg>
                               
                            )}





                        </button>
                        <button className="btn" type="button" onClick={(e) => { e.preventDefault(); handleGenerateSecrets('regeneratePrimaryKey'); }} data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Regenerating">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path
                                    d="M17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C7.90329 2.50789 5.89081 3.32602 4.38333 4.78333L2.5 6.66667M2.5 6.66667V2.5M2.5 6.66667H6.66667M2.5 10C2.5 11.9891 3.29018 13.8968 4.6967 15.3033C6.10322 16.7098 8.01088 17.5 10 17.5C12.0967 17.4921 14.1092 16.674 15.6167 15.2167L17.5 13.3333M17.5 13.3333H13.3333M17.5 13.3333V17.5"
                                    stroke="CurrentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Secondary Key (Same as primary, just duplicate for secondary) */}
            <div className="d-flex gap-2 flex-column">
                <h4 className="mb-0 fw-normal">Secondary Key</h4>
                <div className="primarykey">
                    <input
                        type="text"
                        id="secondary-key"
                        className="form-control bg-transparent border-0 px-0"
                        value={loadingSecondaryKey ? 'Loading...' : showSecondaryKey ? secrets.secondaryKey : maskKey(secrets.secondaryKey)}
                        readOnly
                    />
                    <div className="d-flex align-items-center gap-2">
                        <button
                            className="btn toggle-visibility"
                            type="button"
                            onClick={(e) => { e.preventDefault(); setShowSecondaryKey(!showSecondaryKey); }}
                        >
                            {showSecondaryKey ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path
                                    d="M8.94394 4.23033C10.8851 3.99899 12.8486 4.40934 14.5347 5.3987C16.2207 6.38806 17.5366 7.90205 18.2814 9.70949C18.3509 9.89659 18.3509 10.1024 18.2814 10.2895C17.9752 11.032 17.5704 11.7299 17.0781 12.3645M11.7364 11.7987C11.2649 12.2541 10.6334 12.506 9.97793 12.5003C9.32244 12.4946 8.69541 12.2317 8.23189 11.7682C7.76837 11.3047 7.50545 10.6777 7.49975 10.0222C7.49406 9.36667 7.74604 8.73516 8.20144 8.26366M14.5656 14.5828C13.4602 15.2376 12.2268 15.647 10.9492 15.7832C9.67165 15.9193 8.37973 15.779 7.16113 15.3719C5.94253 14.9647 4.82576 14.3002 3.88661 13.4234C2.94746 12.5467 2.2079 11.4781 1.7181 10.2903C1.64865 10.1032 1.64865 9.89742 1.7181 9.71033C2.45696 7.91854 3.757 6.4147 5.4231 5.42449M1.66644 1.66699L18.3331 18.3337"
                                    stroke="#2967F6" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"
                                />
                            </svg>
                            ) : (
                                
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path
                                    d="M1.7181 10.2898C1.64865 10.1027 1.64865 9.89691 1.7181 9.70981C2.39452 8.06969 3.5427 6.66735 5.01708 5.68056C6.49146 4.69378 8.22564 4.16699 9.99977 4.16699C11.7739 4.16699 13.5081 4.69378 14.9825 5.68056C16.4568 6.66735 17.605 8.06969 18.2814 9.70981C18.3509 9.89691 18.3509 10.1027 18.2814 10.2898C17.605 11.9299 16.4568 13.3323 14.9825 14.3191C13.5081 15.3058 11.7739 15.8326 9.99977 15.8326C8.22564 15.8326 6.49146 15.3058 5.01708 14.3191C3.5427 13.3323 2.39452 11.9299 1.7181 10.2898Z"
                                    stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"
                                />
                                <path
                                    d="M9.99977 12.4998C11.3805 12.4998 12.4998 11.3805 12.4998 9.99981C12.4998 8.6191 11.3805 7.49981 9.99977 7.49981C8.61906 7.49981 7.49977 8.6191 7.49977 9.99981C7.49977 11.3805 8.61906 12.4998 9.99977 12.4998Z"
                                    stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"
                                />
                            </svg>
                            )}
                        </button>
                        <button className="btn" type="button" onClick={(e) => { e.preventDefault(); handleGenerateSecrets('regenerateSecondaryKey'); }} data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Regenerating">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path
                                    d="M17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C7.90329 2.50789 5.89081 3.32602 4.38333 4.78333L2.5 6.66667M2.5 6.66667V2.5M2.5 6.66667H6.66667M2.5 10C2.5 11.9891 3.29018 13.8968 4.6967 15.3033C6.10322 16.7098 8.01088 17.5 10 17.5C12.0967 17.4921 14.1092 16.674 15.6167 15.2167L17.5 13.3333M17.5 13.3333H13.3333M17.5 13.3333V17.5"
                                    stroke="CurrentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <ul className="nav nav-pills gap-3" id="pills-tab-api" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-html-tab" data-bs-toggle="pill" data-bs-target="#pills-html" type="button" role="tab" aria-controls="pills-html" aria-selected="false">HTTP</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link " id="pills-json-tab" data-bs-toggle="pill" data-bs-target="#pills-json" type="button" role="tab" aria-controls="pills-json" aria-selected="true">CURL</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-python-tab" data-bs-toggle="pill" data-bs-target="#pills-python" type="button" role="tab" aria-controls="pills-python" aria-selected="false" tabIndex="-1">Python</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-JavaScript-tab" data-bs-toggle="pill" data-bs-target="#pills-JavaScript" type="button" role="tab" aria-controls="pills-JavaScript" aria-selected="false" tabIndex="-1">JavaScript</button>
                </li>
            </ul>

            <div className="tab-content" id="pills-tabContent-api">
                <div className="tab-pane fade active show" id="pills-html" role="tabpanel" aria-labelledby="pills-html-tab" tabIndex="0">
                    <pre>{code.http}</pre>
                </div>
                <div className="tab-pane fade" id="pills-json" role="tabpanel" aria-labelledby="pills-json-tab" tabIndex="0">
                    <pre>{code.curl} </pre>
                </div>
                <div className="tab-pane fade" id="pills-python" role="tabpanel" aria-labelledby="pills-python-tab" tabIndex="0">
                    <pre>{code.python}
                    </pre>
                </div>
                <div className="tab-pane fade" id="pills-JavaScript" role="tabpanel" aria-labelledby="pills-JavaScript-tab" tabIndex="0">
                    <pre>{code.js}</pre>
                </div>
            </div>
        </div>
    );
};

export default APIKeysForm;
