import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { logInDevelopment } from '../../utils';
import config from '../../config';

const ApiInfo = ({ product, subscriptionId, apiData, hideFreePlanInfo }) => {
  const [secrets, setSecrets] = useState(null);
  const [showPrimaryKey, setShowPrimaryKey] = useState(false);
  const [showSecondaryKey, setShowSecondaryKey] = useState(false);
  const [loadingPrimaryKey, setLoadingPrimaryKey] = useState(false);
  const [loadingSecondaryKey, setLoadingSecondaryKey] = useState(false);
  const [error, setError] = useState(null);
  const ENCRYPTION_KEY = 'HareRamaHareKrishna';

  const encryptJSONData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  };

  const hendleGetSecrets = async () => {
    if (!subscriptionId) return;

    logInDevelopment('subscriptionId = :', subscriptionId);
    console.log('subscriptionId = :', subscriptionId);
    
    const secData = { snd: subscriptionId };
    const encData = encryptJSONData(secData);
    try {
      const response = await axios.post(`${config.ENDPOINT_API_URL}/9335347894`, {
        encpayload: encData,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
      });
      setSecrets(response.data);
    } catch (error) {
      console.error('Error fetching secrets:', error);
      setError('Failed to fetch secrets. Please try again later.');
    }
  };

  const handleGenerateSecrets = async (action) => {
    logInDevelopment(`Regenerating ${action}`);
    const secData = { snd: subscriptionId, action };
    const encData = encryptJSONData(secData);

    if (action === 'regeneratePrimaryKey') setLoadingPrimaryKey(true);
    if (action === 'regenerateSecondaryKey') setLoadingSecondaryKey(true);

    try {
      const response = await axios.post(`${config.ENDPOINT_API_URL}/9335347895`, {
        encpayload: encData,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
      });
      setSecrets(response.data);
    } catch (error) {
      console.error('Error fetching secrets:', error);
      setError('Failed to fetch secrets. Please try again later.');
    } finally {
      if (action === 'regeneratePrimaryKey') setLoadingPrimaryKey(false);
      if (action === 'regenerateSecondaryKey') setLoadingSecondaryKey(false);
    }
  };

  useEffect(() => {
    try {
      hendleGetSecrets();
    } catch (e) {
      setError(e.message);
    }
  }, [subscriptionId]);

  const maskKey = (key) => 'x'.repeat(key.length - 5) + key.slice(-5);

  const filteredCProducts = apiData ? apiData.filter(p => p.productName === product) : [];
  const hideFreePlanInfoStyle = (hideFreePlanInfo === 1) ? { display: 'none' } : [];

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="row mb-5">
      <div className="col-12" style={hideFreePlanInfoStyle}>
        <div className="plan-header">
          <h2 className="page-title">API info</h2>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-5">
            <div className="item mb-2 d-flex gap-2 align-items-center">

              <p className="mb-0">
                {loadingPrimaryKey
                  ? 'Loading...'
                  : secrets
                    ? showPrimaryKey
                      ? secrets.primaryKey
                      : maskKey(secrets.primaryKey)
                    : 'Loading...'}
              </p>
              <span className="d-flex ms-3 anchor-group">
                <a href="#" onClick={(e) => { e.preventDefault(); setShowPrimaryKey(!showPrimaryKey); }} >
                  {showPrimaryKey ? 'Hide' : 'Show'}
                </a>
                <a href="#" onClick={(e) => { e.preventDefault(); handleGenerateSecrets('regeneratePrimaryKey'); }}>
                  Regenerate
                </a>
              </span>
            </div>
            <div className="item mb-2 d-flex gap-2 align-items-center">
              <p className="mb-0">
                {loadingSecondaryKey ? 'Loading...' : secrets ? showSecondaryKey ? secrets.secondaryKey : maskKey(secrets.secondaryKey) : 'Loading...'}
              </p>
              <span className="d-flex ms-3 anchor-group">
                <a href="#" onClick={(e) => { e.preventDefault(); setShowSecondaryKey(!showSecondaryKey); }} >
                  {showSecondaryKey ? 'Hide' : 'Show'}
                </a>
                <a href="#" onClick={(e) => { e.preventDefault(); handleGenerateSecrets('regenerateSecondaryKey'); }}>
                  Regenerate
                </a>
              </span>
            </div>
          </div>
          <div
            className={`col-12 col-lg-6 col-xl-7 ${hideFreePlanInfo === 1 ? 'offset-xl-5 offset-lg-6' : ''}`}
            style={hideFreePlanInfoStyle}
          >
            {filteredCProducts.length > 0 && (
              <div className='api-button-row'>
                {filteredCProducts.slice(0, 1).map((p, index) => (
                  <React.Fragment key={index}>
                    <a className='card-cta-bordered' href={`https://endpoint-console.appypie.com/login?returnUrl=/${p.testYourApi}`} target='_blank' rel='noopener noreferrer'>
                      Test your API
                    </a>
                    <a className='card-cta-bordered' href={p.apiDucumentation} target='_blank' rel='noopener noreferrer'>
                      API Documentation
                    </a>
                    <a className='card-cta-bordered' href="https://endpoint-console.appypie.com/login?returnUrl=/reports" target='_blank' rel='noopener noreferrer'>
                      Reports
                    </a>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>

        </div>
        <div className="row mt-3 d-none">
          <div className="col-12">
            <div className="api-info-list">
              <div className="item">
                <p>Run tests on your API to ensure it's functioning correctly and delivering the desired results.</p>
                <a href="https://endpoint-console.appypie.com/login?returnUrl=/api-details#api=stable-diffusion-xl-base-10&operation=get-image">Test your API</a>
              </div>
              <div className="item">
                <p>Access detailed documentation to guide you through integration and using Stable diffusion XL effectively.</p>
                <a href="https://www.appypie.ai/endpoint/docs/sdxl" target='_blank'>API Documentation</a>
              </div>
              <div className="item">
                <p>View comprehensive reports to monitor your API usage, performance, and analytics.</p>
                <a href="https://endpoint-console.appypie.com/login?returnUrl=/reports">Reports</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiInfo;