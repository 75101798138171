import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { RingLoader } from "react-spinners";
import AddFundForm from "./AddFundForm";
import './AddFromStyle.css';
import SidePanel from "./SidePanel";
import { useLocation ,useNavigate} from 'react-router-dom';
import { logInDevelopment } from "../../utils";
import { fetchProductDetails, storeRecentViewDetails } from "../../services/DashboardServices";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';




function Dashboard() {
    const { isAuthenticated, checkUserSubscription, userDetails } = useContext(AuthContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [productmodel, SetproductModel] = useState('');
    const navigate = useNavigate(); 


    const [productDetails, SetProductDetails] = useState(null);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const productParam = queryParams.get('product');
        SetproductModel(productParam);
        Cookies.set('productParam', productParam, { expires: 7, path: '' });
    }, [location.search]);

   

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const payload = {
                    productName: productmodel,
                }
                const productResponse = await fetchProductDetails(payload);

                if (productResponse.length > 0) {
                    logInDevelopment('Product response:', productResponse);
                    SetProductDetails(productResponse[0]);
                } else {
                    setTimeout(() => {
                        toast.success("Model not found");
                    }, 3000)

                }

            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };
        if (productmodel && productmodel !== 'null') {
            fetchProductData(productmodel);
        }
    }, [productmodel]);

    useEffect(() => {
        if (productmodel && userDetails) {
            storeRecentViewProduct(userDetails, productmodel);
        }
    }, [userDetails, productmodel])

    const storeRecentViewProduct = async (user_email, product_model) => {
        try {
            const payload = {
                userEmail: user_email.email,
                productModel: product_model,
            }
            const responseData = await storeRecentViewDetails(payload);
        } catch (error) {
            console.error('Error store recent view data:', error);
        }

    }

    if (!isAuthenticated && !checkUserSubscription) {
        return (
            <div className="loader-container">
                <div className="loader-inner">
                    <RingLoader color={"#049EFF"} size={100} margin={5} />
                    <p className="loader-text">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <main className="site-main">
                <section className="dashboard-wrap">
                    <div className="container-fluid">
                        <div className="row">
                            <SidePanel step='2' />
                            <div className="col-md-12 col-lg-8">
                                {queryParams.size > 0 && productDetails &&
                                    <div className="flex-md-nowrap flex-wrap model-card">
                                        <img src={productDetails.image_path} alt={productDetails.productDisplayName} className="model-image" />
                                        <div className="model-info">
                                            <h3>{productDetails.productDisplayName}</h3>
                                            <p>
                                                {productDetails.description}
                                            </p>
                                        </div>
                                    </div>
                                }
                                  <AddFundForm />
                            </div>
                          
                        </div>
                    </div>
                </section>
            </main>
        </>
    );



}

export default Dashboard;


