import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { AddFundInWallet, AddFundInWalletFirstTIme, getCreditBalanceFromEmail } from "../../services/DashboardServices";
import {  useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import config from "../../config";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddFundForm() {
  const [amount, setAmount] = useState(10);
  const { userDetails, walletdata, checkUserSubscription } = useContext(AuthContext);
  const navigate = useNavigate();
  const [ringload, setRingLoad] = useState({
    message: "Loading...",
    stateValue: false

  });
  const [isAgree2, setIsAgree2] = useState(false);
  const [isAgree1, setIsAgree1] = useState(false);

  const handleAmountSelect = (selectedAmount) => {
    setAmount(selectedAmount);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

   
    setRingLoad({ stateValue: true, message: "Fund  adding to your wallet." });


    // Initialize the base payload object
    const basePayload = {
      email: userDetails.email,
      productId: checkUserSubscription.subscriptionId,
      productName: "meterBilling",
      itemType: "pushNotification",
      currency: "USD",
      amount: (parseFloat(amount) + Number.EPSILON).toFixed(2),
      country: "US",
      desc: "Fund added in wallet",
      isCredit: "N",
      deductibleWalletAmount: 0,
    };

    // Add additional data based on user status
    if (walletdata.user_status) {
      basePayload.paymentMethod = walletdata.paymentMode;
    } else {
      Object.assign(basePayload, {
        fname: userDetails.first_name,
        lname: userDetails.last_name,
        userId: userDetails.snappy_id,
        address: userDetails.address,
        city: userDetails.city,
        phone: userDetails.phone_number,
        state: userDetails.state,
        country: "US",
        countryCode: "US",
        zip: userDetails.zip_code,
        planId: 65,
        planPeriod: "oneTime",
        paymentMethod: "paypal",
        orderId: checkUserSubscription.subscriptionId,
        successUrl: `${config.API_BASE_URL}/payment-success`,
        cancelUrl: `${config.API_BASE_URL}/dashboard`,
        // notifyUrl:  `${config.ENDPOINT_API_URL} /api/endpoint/webhook/notify`,
        notifyUrl: `https://www.appypiecopy.ai/api/endpoint/webhook/notify`,
        method: "paymentOrder"
      });
    }


    try {
      let response;
      if (walletdata.user_status) {
        response = await AddFundInWallet(basePayload);
        if (response.status === 200) {
          navigate("/api_keys", {
            state: { message: "Funds added successfully!", addFundState: 'success' }
          });

          // window.location.reload();
        } else {
          toast.error("Failed to Add fund");
        }
      } else {

        response = await AddFundInWalletFirstTIme(basePayload);
        if (response.message === "success") {
          navigate(response.url);
        } else {
          toast.error("Failed to add funds for the first time.");
        }
      }

    } catch (error) {
      console.error("Error adding funds:", error);
    } finally {
      setRingLoad({ stateValue: false });
    }
  };


  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setAmount(value);
    }
  };

  //   useEffect(()=>{
  //     if(userDetails){
  //       const data=  getCreditBalanceFromEmail(userDetails.email);
  //       console.log("user fetch balance",data);
  //     } 
  // },[userDetails])

  const handleAgree1Change = () => {
    setIsAgree1(!isAgree1);
  }
  const handleAgree2Change = () => {
    setIsAgree2(!isAgree2);
  }




  return (
    <div className="add-funds-wrap">
      {ringload.stateValue && (
        <div className="loader-container">
          <div className="loader-inner">
            <RingLoader color={"#049EFF"} size={100} margin={5} />
            <p className="loader-text">{ringload.message}</p>
          </div>
        </div>
      )}
      <div className="p-4 border-bottom add-info">
        <h3 className="fs-5 fw-semibold">Add Funds</h3>
        <p>Add funds as per your need</p>
      </div>
      <div className="listed-steps p-4 d-flex flex-column gap-4 align-items-start w-100 justify-content-start">
        {/* Alert */}
        {parseFloat(walletdata.amount) <= 0 && (
          <div className="alert alert-warning d-flex align-items-start gap-2 mb-0 w-100" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
              <path
                d="M10.0002 7.99986V11.3332M10.0002 14.6665H10.0085M18.1085 15.4999L11.4419 3.83319C11.2965 3.5767 11.0857 3.36335 10.831 3.21492C10.5762 3.06649 10.2867 2.98828 9.99185 2.98828C9.69703 2.98828 9.40748 3.06649 9.15275 3.21492C8.89802 3.36335 8.68722 3.5767 8.54185 3.83319L1.87519 15.4999C1.72825 15.7543 1.65121 16.0431 1.65186 16.337C1.65251 16.6308 1.73083 16.9192 1.87889 17.173C2.02695 17.4269 2.23948 17.637 2.49493 17.7822C2.75039 17.9274 3.03969 18.0025 3.33352 17.9999H16.6669C16.9593 17.9996 17.2465 17.9223 17.4996 17.7759C17.7527 17.6295 17.9629 17.4191 18.1089 17.1658C18.255 16.9125 18.3319 16.6252 18.3318 16.3328C18.3317 16.0404 18.2547 15.7531 18.1085 15.4999Z"
                stroke="#EC982A" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div className="alert-contnent">
              <h3>Insufficient Funds</h3>
              <h3 className="d-none">Low Balance</h3>
              <p>This API model costs $0.01/image generation. You currently have $0.00. Add funds to your wallet to get an API key for this model.</p>
            </div>
          </div>
        )}
        {/* End Alert */}

        <div className="w-100 recharge-wrap">
          <h2>Recharge with:</h2>
          <div className="usd-info">
            <span>USD ($)</span>
            <span className="vr"></span>
            <input
              type="text"
              value={amount}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        </div>

        <div className="pricing-tabs w-100">
          <ul className="tabs-listed gap-4 nav border-0 nav-pills align-items-center justify-content-start w-100" id="pills-tab" role="tablist">
            {[
              { value: 10, calls: 500 },
              { value: 50, calls: 1500 },
              { value: 100, calls: 500 },
              { value: 1000, calls: 4000 }
            ].map((option) => (
              <li
                key={option.value}
                className={`nav-item ${amount === option.value ? "active" : ""}`}
                id={`pills-${option.value}-tab`}
                data-bs-toggle="pill"
                data-bs-target={`#pills-${option.value}`}
                aria-controls={`pills-${option.value}`}
                aria-current={amount === option.value ? "true" : "false"}
                onClick={() => handleAmountSelect(option.value)}
              >
                <h4>${option.value}</h4>
                <span>{option.calls} calls</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="tab-content">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              checked={isAgree1}
              onChange={handleAgree1Change}
              id="flexCheckDefault"
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              By adding funds to your wallet, you agree that the funds will be automatically debited based on your usage. Please review our <a href="#" aria-label="pricing page">pricing page</a> to understand the applicable charges.
            </label>
            {/* <small className="text-danger mt-2 d-inline-block">
            {!isAgree1 && 'Checkbox Should be clicked.'}
          </small> */}
          </div>
         
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={isAgree2}
              onChange={handleAgree2Change}
              id="flexCheckDefault2"
            />
            <label className="form-check-label" htmlFor="flexCheckDefault2">
              By checking the consent checkbox, you agree to the auto debit of the total amount, including additional taxes, from your credit card that is already securely added. Enjoy a hassle-free and transparent experience while managing your funds effectively.
            </label>
            {/* <small className="text-danger mt-2 d-inline-block">
            {!isAgree2 && 'Checkbox Should be clicked.'}
          </small> */}
          </div>
         
        </div>

        <div className="billing-info">
          <p>Check all your billing information, wallet usage, and payment method details on our <a href="https://checkout.appypie.com/billing/show" target="blank">billing page</a></p>
        </div>

        <div className="w-100">
          <button
            className={`btn addfunds btn-primary w-100 py-2 ${amount < 10 || !isAgree1 || !isAgree2 ? 'disabled' : ''}`}
            onClick={handleSubmit}
            disabled={amount < 10 || !isAgree1 || !isAgree2}
          >
            Add Funds
          </button>
          <small className="text-danger mt-2 d-inline-block">
            {amount < 10 && 'Minimum amount must be at least $10.'}
          </small>
        </div>

      </div>
    </div>
  );
}

export default AddFundForm;
