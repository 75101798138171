import React, { useState, useEffect, useContext, } from "react";
import SidePanel from "./SidePanel";
import { useLocation,useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { getRecentViewDataProductDetails, getSuggestedModels } from "../../services/DashboardServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from "./Dropdown";
import APIKeysForm from "./APIKeysForm";


function AfterPaymentSuccess() {

    const { userDetails } = useContext(AuthContext);
    const location = useLocation();
    const { addFundState } = location.state || {};
    const [productDetails, SetProductDetails] = useState([]);
    const [suggestedModel, SetSuggestedModel] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (userDetails) {
            getRecentProductView(userDetails.email);
            suggestedProducts();
        }
    }, [userDetails])


    const getRecentProductView = async (user_email) => {

        const payload = { userEmail: user_email };
        const recentviewsdetails = await getRecentViewDataProductDetails(payload);
        if (recentviewsdetails.status === 200) {
            const data = recentviewsdetails.response.productDetails.flat();
            SetProductDetails(data);
        }

    }

    const suggestedProducts = async () => {
        const suggestproductdetails = await getSuggestedModels();
        if (suggestproductdetails.status === 200) {
            const data = suggestproductdetails.response.productDetails.flat();
            SetSuggestedModel(data);
        }
    }

    useEffect(() => {
        if (addFundState === 'success') {
            toast.success("Fund successfully added to your wallet.");
        }
    }, [addFundState]);

    return (
        <>
            <main className="site-main">
                <section className="dashboard-wrap">
                    <div className="container-fluid">
                        <div className="row">
                            <SidePanel step='3' />
                            <div className="col-md-12 col-lg-4">
                                {loading && (
                                    <div className="skeleton-wrapper">
                                        <div className="skeleton skeleton-header"></div>
                                        <div className="skeleton skeleton-line" style={{ height: "40px" }}></div>
                                        <div className="skeleton skeleton-line"></div>
                                        <div className="skeleton skeleton-line"></div>
                                        <div className="skeleton skeleton-line" style={{ height: "40px" }}></div>
                                        <div className="skeleton skeleton-line"></div>
                                        <div className="skeleton skeleton-line" style={{ height: "40px" }}></div>
                                        <div className="skeleton skeleton-box"></div>
                                    </div>
                                )}
                                 {!loading && (
                                <div className="aside-card p-4 bg-white d-flex flex-column gap-3" id="asideCard">
                                    <Dropdown />
                                    <APIKeysForm />
                                    <div className="col-md-12 col-lg-4">

                                    </div>

                                </div>
                                 )}
                            </div>

                            <div className="col-md-12 col-lg-4">
                                <div className="d-flex flex-column gap-3 recently-view mt-3 mt-md-0">

                                    <div className="d-flex flex-column gap-3 scrollheightView">
                                        {productDetails && productDetails.length > 0 && (
                                            <h2 className="mb-0">Recently Viewed</h2>
                                        )}

                                        {productDetails && productDetails.length > 0 &&
                                            productDetails
                                                .filter((value, index, self) =>
                                                    index === self.findIndex((t) => (
                                                        t.productName === value.productName
                                                    ))
                                                )
                                                .map((product) => (
                                                    <div className="card flex-row gap-3 p-2 card-recently align-items-center" key={product.productId || product.productName}>
                                                        <div className="card-header p-0 flex-shrink-0 border-0 bg-white">
                                                            <img
                                                                src={product.image_path}
                                                                alt={product.productDisplayName}
                                                                width="90"
                                                                height="90"
                                                                className="img-fluid object-fit-cover rounded-2"
                                                            />
                                                        </div>
                                                        <div className="card-body p-0">
                                                            <h3 className="card-title">{product.productDisplayName}</h3>
                                                            <p className="card-text">{product.description}</p>
                                                        </div>
                                                    </div>
                                                ))
                                        }


                                        <div className="d-flex justify-content-between gap-3 my-2 flex-wrap">
                                            <h4 className="mb-0 fs-14">Suggested Models </h4>
                                            <a href="https://www.appypieapi.ai/explore" className="fw-semibold small">View all models</a>
                                        </div>
                                        {suggestedModel.map((product, index) => (
                                            <div className="card flex-row gap-3 p-2 card-recently align-items-center">
                                                <div className="card-header p-0 flex-shrink-0 border-0 bg-white">
                                                    <img src={product.image_path} alt={product.productDisplayName} width="90" height="90" className="img-fluid object-fit-cover rounded-2" />
                                                </div>
                                                <div className="card-body p-0">
                                                    <h3 className="card-title">{product.productDisplayName}</h3>
                                                    <p className="card-text">{product.description}</p>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
        </>
    )

}

export default AfterPaymentSuccess;

